import React from "react"
import { Routes, Route } from 'react-router-dom'
import Main from './pages/Main'
import Dishes from './pages/Dishes'
import Menus from './pages/Menus'
import About from './pages/About'
import Contacts from './pages/Contacts'
import Cart from './pages/Cart'
import Privacy from './pages/Privacy'
import Cookies from './pages/Cookies'
import Report from './pages/Report'
import ThankYouPage from './pages/ThankYouPage'

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/dishes" element={<Dishes/>}/>
        <Route path="/menus" element={<Menus/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contacts" element={<Contacts/>}/>
        <Route path="/cart" element={<Cart/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/cookies" element={<Cookies/>}/>
        <Route path="/report" element={<Report/>}/>
        <Route path="/thankyou" element={<ThankYouPage/>}/>
      </Routes>
    </div>
  )
}

export default App
