import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class CartStore {
  personName = '';
  personPhone = '';
  cartItems = [];
  cartTotalPrice = 0;

  constructor() {
    makeAutoObservable(this);
    const properties = ['personName', 'personPhone', 'cartItems', 'cartTotalPrice'];
    makePersistable(this, { name: 'CartStore', properties, storage: window.localStorage });
  }

  addItem(id, title, price) {
    if (this.cartItems.findIndex(item => item.id === id) >= 0)
      return;
    const item = { id, title, price, totalPrice: price, count: 1 };
    this.cartItems.push(item);
    this.cartTotalPrice += item.price;
  }

  // count is +1 / -1
  incCount(index, count) {
    const item = this.cartItems[index];
    item.count += count;
    const addPrice = count * item.price;
    item.totalPrice += addPrice;
    this.cartTotalPrice += addPrice;
    if (item.count === 0)
      this.cartItems.splice(index, 1);
  }

  removeItem(index) {
    const item = this.cartItems[index];
    this.cartTotalPrice -= item.price * item.count;
    this.cartItems.splice(index, 1);
  }

  clearItems() {
    this.cartItems = [];
    this.cartTotalPrice = 0;
  }

  get items() {
    return this.cartItems;
  }

  get totalPrice() {
    return this.cartTotalPrice;
  }

  get totalItemsCount() {
    return this.cartItems.length;
  }

  get name() {
    return this.personName;
  }

  setName(name) {
    this.personName = name;
  }

  get phone() {
    return this.personPhone;
  }

  setPhone(phone) {
    this.personPhone = phone;
  }

  hasItem(id) {
    return this.cartItems.find(item => item.id === id) != null;
  }
}

export const cartStore = new CartStore();
