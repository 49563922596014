import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Input from '../components/Input'
import styles from './Contacts.module.css'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LetterIcon } from '../assets/letter-icon.svg'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

function isEmptyStr(x) {
  return typeof x === 'string' && x.trim() === '';
}

function isEmailValid(text) {
  const mailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return text.match(mailPattern);
};

function Contacts() {
  const { t } = useTranslation()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const [error, setError] = useState(null);

  const onClick = () => {
    const data = { name, phone, email, message };
    const empty = Object.values(data).find(isEmptyStr) != null;
    if (empty) {
      setError('empty');
      return;
    }
    if (!isEmailValid(email)) {
      setError('invalidEmail');
      return;
    }
    setError('loading');
    fetch('/api/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(res => {
      if (!res.ok)
        setError('server');
      else
        setError('no');
    }).catch(() => setError('server'));
  }
  return (
    <div className="Contacts">
      <ScrollToTopOnMount />
      <Navbar />
      <div className={styles.contactsContainer}>
        <div className={styles.leftBlock}>
          <p className={styles.adress}>Pilevænget 35, Fårup, Danmark</p>
          <p>middagpaukrainsk@gmail.com</p>
          <p>{t('contacts-phone-number')} 50-31-93-18</p>
          <iframe
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCcywKcxXeMZiMwLDcLgyEnNglcLOyB_qw&amp;q=Pilevænget 35, Fårup&amp;zoom=15"
            title="map"
          >
          </iframe>
        </div>

        <div className={styles.rightBlock}>
          <h2>{t('contacts-title')}</h2>
          {error === 'loading' && <p className={styles.loadingStyle}>{t('contacts-loading-message')}</p>}
          {error === 'empty' && <p className={styles.errorStyle}>{t('contacts-empty-message')}</p>}
          {error === 'invalidEmail' && <p className={styles.errorStyle}>{t('contacts-incorrect-email-message')}</p>}
          {error === 'no' && <p className={styles.messageStyle}>{t('contacts-success-message')}</p>}
          {error === 'server' && <p className={styles.errorStyle}>{t('contacts-error-message')}</p>}
          <Input placeholder={t('contacts-name')}
                 value={name} onChange={setName} />
          <Input placeholder={t('contacts-email')}
                 value={email} onChange={setEmail} />
          <Input placeholder={t('contacts-phone-number')}
                 value={phone} onChange={setPhone} />
          <textarea placeholder={t('contacts-message')}
                    value={message} onChange={onMessageChange} />
          <button onClick={onClick}>
            <LetterIcon />
            <span>{t('contacts-button')}</span>
          </button>
        </div>
      </div>
      <Footer />
      <ButtonUp />
    </div>
  )
}

export default Contacts
