import styles from './Privacy.module.css'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

function Privacy() {
    const { t } = useTranslation()
    return (
        <div className={styles.privacyContainer}>
            <ScrollToTopOnMount/>
            <Navbar />
            <div className={styles.textContainer}>
                <h2>{t('privacy-heading-1')}</h2>
                <p>{t('privacy-text-1')}</p>
                <h2>{t('privacy-heading-2')}</h2>
                <p>{t('privacy-text-2')}</p>
                <h2>{t('privacy-heading-3')}</h2>
                <p>{t('privacy-text-3')}</p>
                <h2>{t('privacy-heading-4')}</h2>
                <p>{t('privacy-text-4')}</p>
            </div>
            <ButtonUp/>
            <Footer/>
        </div>
    )
}

export default Privacy