import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import styles from './Main.module.css'
// import { ReactComponent as BreakfastIcon } from '../assets/breakfast-icon.svg'
import { ReactComponent as LunchIcon } from '../assets/lunch-icon.svg'
import { ReactComponent as DinnerIcon } from '../assets/dinner-icon.svg'
import { ReactComponent as DessertIcon } from '../assets/dessert-icon.svg'
import { ReactComponent as BroccoliIcon } from '../assets/broccoli-icon.svg'
import { ReactComponent as SignatureDishesIcon } from '../assets/signature-dishes-icon.svg'
import { ReactComponent as FamilyIcon } from '../assets/family-icon.svg'
import { ReactComponent as CalendarIcon } from '../assets/calendar-icon.svg'
import { ReactComponent as CutleryIcon } from '../assets/cutlery-icon.svg'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
// import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg'
// import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg'

function Main() {
  const { t } = useTranslation()
  return (
    <div className={styles.main}>
        <ScrollToTopOnMount/>
        <Navbar/>
        <div className={styles.header}>
          <h1 className={styles.menusHeading}>{t('main-header-title-1')} <span>{t('main-header-title-2')}</span></h1>
          <h1>{t('main-header-title-3')}</h1>
          <p>{t('main-header-description')}</p>
          <Link to='/dishes'>{t('main-header-button')}</Link>
        </div>
        <nav className={styles.dishesGroupsContainer}>
          <div>
            <Link to="/dishes#firstcources"><LunchIcon/>{t('main-dishes-group-1')}</Link>
            <Link to="/dishes#frozenproducts"><DinnerIcon/>{t('main-dishes-group-2')}</Link>
            <Link to="/dishes#desserts"><DessertIcon/>{t('main-dishes-group-3')}</Link>
            <Link to="/dishes#salads"><BroccoliIcon/>{t('main-dishes-group-4')}</Link>
            <Link to="/menus"><SignatureDishesIcon/>{t('main-dishes-group-5')}</Link>
          </div>
        </nav>
        <div className={styles.ourAim}>
          <h2 className={styles.aimHeading}>{t('main-aim-title')}</h2>
          <div className={styles.everydayBlock}>
            <div className={styles.iconContainer}><SignatureDishesIcon/></div>
            <div>
              <h3>{t('main-aim-daily-heading')}</h3>
              <p>{t('main-aim-daily-description')}</p>
            </div>
          </div>
          <div className={styles.familyBlock}>
            <div className={styles.iconContainer}><FamilyIcon/></div>
            <div>
              <h3>{t('main-aim-family-heading')}</h3>
              <p>{t('main-aim-family-description')}</p>
            </div>
          </div>
          <div className={styles.calendarBlock}>
            <div className={styles.iconContainer}><CalendarIcon/></div>
            <div>
              <h3>{t('main-aim-holiday-heading')}</h3>
              <p>{t('main-aim-holiday-description')}</p>
            </div>
          </div>
        </div>
        <div className={styles.ordersContainer}>
          <h1 className={styles.ordersHeading}>{t('main-popular-orders-title')}</h1>
          <div className={styles.ordersBlock}>
            <div className={styles.popularOrders}>
              <div className={styles.holidayBlock}>
                <div className={styles.picContainer}><img src={require("../assets/holiday-menu-square.jpg")} alt="holiday menu"/></div>
                <div className={styles.popularOrdersInfo}>
                  <h4>{t('main-popular-orders-holiday-title')}</h4>
                  <p>{t('main-popular-orders-holiday-description')}</p>
                </div>
              </div>
              <div className={styles.kingsBlock}>
                <div className={styles.picContainer}><img src={require("../assets/kings-menu-square.jpg")} alt="kings menu"/></div>
                <div className={styles.popularOrdersInfo}>
                  <h4>{t('main-popular-orders-kings-title')}</h4>
                  <p>{t('main-popular-orders-kings-description')}</p>
                </div>
              </div>
              <div className={styles.standardBlock}>
                <div className={styles.picContainer}><img src={require("../assets/standard-menu-square.jpg")} alt="standard menu"/></div>
                <div className={styles.popularOrdersInfo}>
                  <h4>{t('main-popular-orders-standard-title')}</h4>
                  <p>{t('main-popular-orders-standard-description')}</p>
                </div>
              </div>
            </div>
            <div className={styles.makeOrder}>
              <div className={styles.iconContainer}><CutleryIcon/></div>
              <p className={styles.makeOrderHeading}>{t('main-to-order')}</p>
              <p className={styles.makeOrderCall}>{t('main-call-us')}</p>
              <a href='tel:+4550319318' className={styles.makeOrderNumber}>+45 50 31 93 18</a>
            </div>
          </div>
        </div>
        <div className={styles.about}>
          <h2 className={styles.aboutHeading}>{t('main-about-title')}</h2>
          <p>{t('main-about-description')}</p>
          <Link to='/about'>{t('main-about-button')}</Link>
        </div>
        <ButtonUp/>
        <Footer/>
    </div>
  )
}

export default Main
