// import styles from './Input.module.css'
import './Input.module.css'

function Input(props) {
  const onInputChange = (event) => {
    props.onChange(event.target.value);
  }
  return (
    <input value={props.value}
           onChange={onInputChange}
           type={props.type || "text"}
           placeholder={props.placeholder}
           disabled={props.disabled}/>
  );
}

export default Input;
