import styles from './Cookies.module.css'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next'
import ButtonUp from '../components/ButtonUp'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'

function Cookies() {
    const { t } = useTranslation()
    return (
        <div className={styles.cookiesContainer}>
            <ScrollToTopOnMount/>
            <Navbar />
            <div className={styles.textContainer}>
                <h2>{t('cookies-heading')}</h2>
                <p>{t('cookies-text')}</p>
                <div className={styles.links}>
                    <a href='https://minecookies.org/cookie-wall?url_redirect=http://minecookies.org/cookiehandtering/'>minecookies.org/cookiehandtering</a><br/>
                    <a href='https://allaboutcookies.org/'>allaboutcookies.org</a>
                </div>
            </div>
            <ButtonUp/>
            <Footer/>
        </div>
    )
}

export default Cookies